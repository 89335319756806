<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">平台资源</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">平台课程/课件</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="平台课程" name="first">
            <CourseList ref="first" />
          </el-tab-pane>
          <el-tab-pane label="平台课件" name="second">
            <Curriculum ref="second" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import CourseList from "../platform/popup/course";
import Curriculum from "../platform/popup/Curriculum";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "platFormCourseList",
  components: {
    CourseList,
    Curriculum
  },
  data() {
    return {
      activeName: "first"
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    init() {
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "first";
      }
    },
    tabChange(tab) {
      this.$refs[tab.name].searchName = "";
      this.$refs[tab.name].params = {};
      this.$refs[tab.name].ruleForm.Trainingtype = "";
      this.$refs[tab.name].completeState = "";
      this.$refs[tab.name].getData();
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route:function(route){
      if(route.path !='/web/courseList') return;
      if(route.query.refresh){
        this.$refs[route.query.refName].getData(-1);
      }
    }
  }
};
</script>
<style lang="less">
</style>
